<template>
  <LoginLayout :image="image" :wit-min-height="isPage">
    <client-only>
      <Form v-slot="{ meta }" as="" :class="[contentClasses]">
        <div
          class="flex w-full flex-col items-center"
          :class="{ 'px-small md:px-big': !isPage }"
        >
          <div class="w-full">
            <h2
              id="signin-modal-title"
              class="mb-10 text-left text-2xl font-bold text-gray-900"
            >
              {{ SIGN_IN }}
            </h2>
          </div>
          <form class="w-full" @submit.prevent="signIn">
            <input type="hidden" name="remember" value="true" />
            <div class="flex flex-col gap-y-3">
              <CommonFormInput
                v-model="form.username"
                name="username"
                rules="required"
                data-testid="email"
                label="Username or Email"
                class="w-full"
              />
              <CommonFormInput
                v-model="form.password"
                name="password"
                rules="required"
                data-testid="password"
                type="password"
                autocomplete="current-password"
                label="Password"
                class="w-full"
              />
            </div>

            <div class="text-right">
              <button
                type="button"
                class="text-sm font-semibold underline"
                @click="openForgotPasswordModal"
              >
                Forgot your password?
              </button>
            </div>

            <div class="text-error text-center">{{ error }}</div>

            <div
              class="mt-4 flex w-full items-center gap-x-6 gap-y-3"
              :class="
                isPage ? 'mt-5 flex-col md:flex-row-reverse' : 'mt-3.5 flex-col'
              "
            >
              <CommonButton
                data-testid="login"
                class="sign-in-form-button px-16 text-sm lg:w-28 whitespace-nowrap"
                :class="isPage ? '' : '!w-full'"
                variant="primary"
                type="submit"
                :disabled="(!meta.valid && !autofilled) || loading"
                :show-spinner="loading"
              >
                {{ SIGN_IN }}
              </CommonButton>

              <button
                class="w-fit font-semibold underline lg:w-28"
                @click.prevent="openContactModal"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </Form>
    </client-only>
  </LoginLayout>
</template>

<script setup lang="ts">
import { Form } from 'vee-validate'
import { SIGN_IN } from '~/constants/services/constants'
import LoginLayout from '~/components/common/LoginLayout.vue'
import { useStore } from 'vuex'

const props = withDefaults(
  defineProps<{
    image: string
    contentClasses?: string
    module?: 'page' | 'modal'
  }>(),
  {
    contentClasses: '',
    module: 'modal',
  },
)

const store = useStore()
const route = useRoute()
const router = useRouter()
const { $modal, $bmAuth, $membershipApi } = useNuxtApp()

const form = reactive({
  username: '',
  password: '',
})
const error = ref(null)
const loading = ref(false)
const autofilled = ref(false)

const isPage = computed(() => props.module === 'page')

const openForgotPasswordModal = () => {
  $modal.close('signIn')
  $modal.open('forgotPassword')
}

const openContactModal = () => {
  $modal.close('signIn')
  $modal.open('contact')
}

const guessRedirect = async () => {
  // Redirect the user to the home page if logged in on the login
  // page without a next_page param
  if (!route.query.next_page && props.module === 'page') {
    return router.push('/')
  }

  if (route.query.next_page) {
    return router.push(route.query.next_page as string)
  }
}

const executeCallback = async () => {
  const callback = store.state.modal.callback

  if (typeof callback === 'function') {
    await callback()
    return
  }

  await guessRedirect()
}

const signIn = async () => {
  loading.value = true
  error.value = ''

  try {
    await $bmAuth.login(form)
    await $membershipApi.getActiveMemberships()
    await executeCallback()
    $modal.close('signIn')
  } catch (e) {
    logError(e)
    error.value = 'Incorrect username/password. Please try again.'
  } finally {
    loading.value = false
  }
}
</script>
